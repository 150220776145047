import ApiBase from './api-base.js'
import { v2m5mHeaders } from '@/modules/config/api'
import { NOTIFICATION_SUBSCRIPTION_LOCAL_STORAGE_KEY } from '../../constants/productLaunchConstants'

class User extends ApiBase {
  constructor () {
    super()
    this.url = '/v2.5/user'
  }

  async fetchRetailOrders ({
    pageSize = 8,
    pageNumber = 1
  } = {}) {
    const url = `/v2.3/account/retail_orders`
    const { data } = await this.client.get(url, {
      params: {
        include: 'retail_store',
        'page[size]': pageSize,
        'page[number]': pageNumber
      }
    })

    return data
  }

  async fetchStorePurchases ({
    pageSize = 8,
    pageNumber = 1
  } = {}) {
    const url = `${this.url}/retail_orders`
    const { data } = await this.client.get(url, {
      headers: v2m5mHeaders,
      params: {
        include: 'retail_line_items',
        'page[size]': pageSize,
        'page[number]': pageNumber
      }
    })

    return data
  }

  async fetchOrderDetails (args) {
    const endpoint = (args.retail === true) ? 'retail_orders' : 'orders'
    const url = `${this.url}/${endpoint}/${args.orderId}`

    const { data } = await this.client.get(url, {
      headers: v2m5mHeaders,
      params: args.params
    })

    return data
  }

  async fetchRetailOrderDetails (args) {
    const url = `${this.url}/orders/${args.orderId}`
    const { data } = await this.client.get(url, {
      headers: v2m5mHeaders,
      params: args.params
    })

    return data
  }

  async fetchOnlineOrders ({
    pageSize = 8,
    pageNumber = 1
  } = {}) {
    const url = `${this.url}/orders`
    const { data } = await this.client.get(url, {
      headers: v2m5mHeaders,
      params: {
        include: 'line_items',
        'page[size]': pageSize,
        'page[number]': pageNumber
      }
    })

    return data
  }

  async fetchMarketplaceOrders ({
    pageSize = 8,
    pageNumber = 1
  } = {}) {
    const url = `${this.url}/marketplace_orders`
    const { data } = await this.client.get(url, {
      headers: v2m5mHeaders,
      params: {
        'page[size]': pageSize,
        'page[number]': pageNumber
      }
    })

    return data
  }

  async fetchSubscriptions (userId) {
    const localStorageKey = `${NOTIFICATION_SUBSCRIPTION_LOCAL_STORAGE_KEY}${userId}`
    let storedData = JSON.parse(localStorage.getItem(localStorageKey))

    if (!storedData || storedData.expiryTime < new Date().getTime()) {
      storedData = await this.fetchAndStoreData(localStorageKey)
    }

    return storedData.data
  }

  async subscribeVariant (args) {
    const url = '/api/v2.5/notification_subscriptions/subscribe'
    await this.client.post(url, args.notificationParams, {
      headers: v2m5mHeaders
    })

    const localStorageKey = `${NOTIFICATION_SUBSCRIPTION_LOCAL_STORAGE_KEY}${args.userId}`
    return this.fetchAndStoreData(localStorageKey)
  }

  async fetchAndStoreData (localStorageKey) {
    const EXPIRY_TIME = 30 * 60 * 1000
    const subscriptionData = {
      data: [],
      expiryTime: new Date().getTime() + EXPIRY_TIME
    }
    const url = '/v2.5/notification_subscriptions'
    const { data } = await this.client.get(url, {
      params: {
        'page[size]': 500
      },
      headers: v2m5mHeaders
    })
    data.data.forEach(item => {
      subscriptionData.data.push(...item.subscribable_ids)
    })
    localStorage.setItem(localStorageKey, JSON.stringify(subscriptionData))

    return subscriptionData
  }
}

export default User
