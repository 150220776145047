import { notifyBugsnag } from '@/modules/services/bugsnag'
import User from '@/modules/api/user'
import i18n from '@/modules/i18n'

const notificationSubscriptionApi = new User()

const state = {
  earlyAccessNotificationSubscriptions: []
}

const mutations = {
  setEANotificationSubscriptions (state, array) {
    state.earlyAccessNotificationSubscriptions = array
  }
}

const actions = {
  async fetchNotificationSubscriptions ({ commit, rootState: { user } }) {
    const userId = user?.currentUser?.id
    if (!userId) return

    try {
      const notificationSubscriptions = await notificationSubscriptionApi.fetchSubscriptions(
        userId
      )
      commit('setEANotificationSubscriptions', notificationSubscriptions)
    } catch (error) {
      notifyBugsnag(error)
    }
  },

  async addVariantsToNotificationSubscription (
    { commit, rootState: { user } },
    { variantId, eventName, eventType }
  ) {
    const userId = user?.currentUser?.id
    if (!userId) return

    const params = {
      event_type: eventType,
      event_name: eventName,
      subscribable_type: 'Variant',
      subscribable_id: variantId
    }

    try {
      const { data } = await notificationSubscriptionApi.subscribeVariant({ notificationParams: params, userId })

      commit('setEANotificationSubscriptions', data)

      commit('setNotification', {
        type: 'success',
        msg: i18n.t('early_access.successfullyNotified')
      })
    } catch (error) {
      notifyBugsnag(error)
    }
  }
}

export default {
  state,
  mutations,
  actions
}
