import axios from 'axios'
import * as ApiConfig from '@/modules/config/api'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { refreshTokenPath } from '@/modules/config/authentication'
import { removeDuplicatedApiInterceptor } from '@/modules/api/interceptors'

const noAuthRefreshRoutes = ['/v2.6/users/password', '/v2.6/auth/social/login']

export const getCSRFToken = () => {
  return document.getElementsByName('csrf-token')[0]
    ? document.getElementsByName('csrf-token')[0].getAttribute('content')
    : null
}

class ApiBase {
  constructor ({ extendedHeaders = {} } = {}) {
    const { headers, routes, v2m5mHeaders } = ApiConfig
    const csrfToken = getCSRFToken()

    this.resourceURL = routes.baseURL + routes.basePath
    const mergedHeaders = {
      ...(csrfToken ? { 'X-CSRF-TOKEN': csrfToken } : {}),
      ...headers,
      ...v2m5mHeaders,
      ...extendedHeaders
    }

    this.client = axios.create({
      baseURL: this.resourceURL,
      headers: mergedHeaders
    })

    removeDuplicatedApiInterceptor(this.client)
    refreshTokenInterceptor(this.client, this.resourceURL, mergedHeaders)
  }
}

export function refreshTokenInterceptor (axiosInstance, baseUrl, headers) {
  createAuthRefreshInterceptor(axiosInstance, async (failedRequest) => {
    if (noAuthRefreshRoutes.includes(failedRequest.config.url)) return Promise.reject(failedRequest.response)

    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.jsMessageHandler) {
      return window.webkit.messageHandlers.jsMessageHandler.postMessage('EXPIRED_TOKEN')
    }

    if (typeof MessageHandler !== 'undefined' && typeof MessageHandler.postMessage === 'function') { // eslint-disable-line no-undef
      return MessageHandler.postMessage('EXPIRED_TOKEN') // eslint-disable-line no-undef
    }

    const data = await fetch(`${baseUrl}${refreshTokenPath}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        grant_type: 'refresh_token',
        flow: 'client'
      }),
      keepalive: true
    })

    if (data.status >= 200 && data.status < 300) {
      return Promise.resolve()
    } else {
      window.location.replace('/sign_in')

      return Promise.reject(data)
    }
  })
}

export default ApiBase
