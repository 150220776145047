import Wishlist from '@/modules/api/wishlist'
import { notifyBugsnag } from '@/modules/services/bugsnag'

const wishlistApi = new Wishlist()

const state = {
  wishlist: [],
  variants: [],
  displayedVariants: [],
  products: [],
  filters: [],
  wishlistLoading: false,
  wishlistFilterLoading: false,
  totalPages: 1,
  remindersTotalPages: 1,
  pageNumber: 1,
  reminders: []
}

const mutations = {
  setWishlist (state, array) {
    state.wishlist = array
  },

  setWishlistVariants (state, array) {
    state.variants = array
  },

  setWishlistDisplayedVariants (state, array) {
    state.displayedVariants = array
  },

  setWishlistProducts (state, array) {
    state.products = array
  },

  setWishlistLoading (state, bool) {
    state.wishlistLoading = bool
  },

  setWishlistFilterLoading (state, wishlistFilterLoading) {
    state.wishlistFilterLoading = wishlistFilterLoading
  },

  setWishlistFilters (state, array) {
    state.filters = array
  },

  setTotalPages (state, totalPages) {
    state.totalPages = totalPages
  },

  setPageNumber (state, pageNumber) {
    state.pageNumber = pageNumber
  },

  setWishlistReminders (state, array) {
    state.reminders = array
  },

  setRemindersTotalPages (state, totalPages) {
    state.remindersTotalPages = totalPages
  }
}

const actions = {
  async fetchWishlist ({ commit, state, rootGetters }, args = {}) {
    commit('setWishlistLoading', true)

    try {
      const { data, included, meta } = await wishlistApi.fetch(args)

      commit('setWishlistVariants', data || [])
      commit('setWishlistDisplayedVariants', data || [])
      commit('setWishlistProducts', included || [])
      commit('setTotalPages', parseInt(meta['total-pages']) || 1)
    } catch (error) {
      notifyBugsnag(error)
    } finally {
      commit('setWishlistLoading', false)
    }
  },

  async fetchReminders ({ commit, state, rootGetters }, args = {}) {
    commit('setWishlistLoading', true)

    try {
      const { data, included, meta } = await wishlistApi.fetchReminders(args)

      commit('setWishlistReminders', data || [])
      commit('setWishlistProducts', included || [])
      commit('setRemindersTotalPages', meta['total-pages'] || 1)
    } catch (error) {
      notifyBugsnag(error)
    } finally {
      commit('setWishlistLoading', false)
    }
  },

  async fetchWishlistVariants ({ commit, state }, args = {}) {
    commit('setWishlistLoading', true)

    try {
      const { data } = await wishlistApi.fetchVariants(args)
      const wishListVariants = data.relationships.variants.data.map(variant => parseInt(variant.id))

      commit('setWishlist', wishListVariants)
      localStorage.setItem('wishlistedVariants', JSON.stringify(wishListVariants))
    } catch (error) {
      notifyBugsnag(error)
    } finally {
      commit('setWishlistLoading', false)
    }
  },

  async fetchWishlistFilters ({ commit }) {
    commit('setWishlistFilterLoading', true)

    try {
      const { included } = await wishlistApi.filters()

      commit('setWishlistFilters', included || [])
    } catch (error) {
      notifyBugsnag(error)
    } finally {
      commit('setWishlistFilterLoading', false)
    }
  },

  async addVariantsToWishlist ({ commit, state }, values = []) {
    try {
      const { data } = await wishlistApi.add(values.join())
      const wishListVariants = data.relationships.variants.data.map(variant => parseInt(variant.id))

      commit('setWishlist', wishListVariants)

      localStorage.setItem('wishlistedVariants', JSON.stringify(wishListVariants))
    } catch (error) {
      notifyBugsnag(error)
    }
  },

  async removeVariantsFromWishlist ({ commit, state }, values = []) {
    try {
      const { data, included } = await wishlistApi.remove(values.join())
      const wishListVariants = data.relationships.variants.data.map(variant => parseInt(variant.id))
      const variants = included.filter((i) => i.type === 'variants')

      commit('setWishlist', wishListVariants)
      commit('setWishlistVariants', variants)

      localStorage.setItem('wishlistedVariants', JSON.stringify(wishListVariants))
    } catch (error) {
      notifyBugsnag(error)
    }
  }
}

export default {
  state,
  mutations,
  actions
}
