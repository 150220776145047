import { KameleoonClient, CustomData } from '@kameleoon/javascript-sdk'
import secrets from '@/modules/config/secrets'

// https://developers.kameleoon.com/feature-management-and-experimentation/web-sdks/js-sdk/#reference

const { kameleoonUpdateInterval, env, isMobile } = window.vueSupport

const customDataKeyIndices = {
  tier: 0,
  country: 3,
  platform: 5,
  app_platform: 6,
  version: 7,
  key: 8,
  brand_code: 9,
  page_path: 10
}

class Kameleoon {
  #client
  #visitorCode
  #isSDKReady = false

  constructor () {
    this.#client = new KameleoonClient({
      siteCode: secrets.kameleoonSiteCode,
      configuration: {
        // In minutes, in-sync with backend interval
        updateInterval: kameleoonUpdateInterval || 5,
        // Maps to our Kameleoon environments 'production', 'staging', 'development'
        environment: env
      }
    })
    this.initialize()
  }

  async initialize () {
    try {
      await this.#client.initialize()

      this.#isSDKReady = true

      // If the cookie does not exist Kameleoon will generate and store it automatically
      // If the cookie already exists Kameleoon will fetch the previous value already stored
      this.#visitorCode = this.#client.getVisitorCode()

      const country = window.I18n.siteCountryLs.toUpperCase()
      const platform = isMobile ? 'mobile_web' : 'web'
      this.#client.addData(
        this.#visitorCode,
        new CustomData(customDataKeyIndices.country, country)
      )
      this.#client.addData(
        this.#visitorCode,
        new CustomData(customDataKeyIndices.platform, platform)
      )

      // https://developers.kameleoon.com/feature-management-and-experimentation/web-sdks/js-sdk/#flush
      // Skip calling client.flush as the SDK will automatically sync periodically,
      // but we should manually call flush if we need immediate syncing for some reason
    } catch (error) {
      console.error('Could not initialize Kameleoon client', error)
    }
  }

  get client () {
    return this.#client
  }

  immediateSync () {
    this.#client.flush()
  }

  isFeatureFlagActive (featureFlagName = '') {
    if (!this.#isSDKReady) {
      console.error('Kameleoon client is not ready')
      return false
    }

    return this.#client.isFeatureFlagActive(this.#visitorCode, featureFlagName)
  }
}

export default new Kameleoon()
