import ApiBase from './api-base'
import { v2m5mHeaders } from '@/modules/config/api'
import get from 'lodash/get'
import UserAdapter from './adapters/user'
import { notifyBugsnag } from '@/modules/services/bugsnag'

class Authentication extends ApiBase {
  constructor () {
    super()
    this.apiVersion = '/v2.5'
    this.accountUrl = `${this.apiVersion}/accounts`
    this.authUrl = `${this.apiVersion}/auth`
    this.adapters = {
      user: new UserAdapter()
    }
  }

  async existingUser ({
    email = ''
  }) {
    const { data } = await this.client.post('/v2.6/accounts/check_existence', {
      email
    }, {
      headers: v2m5mHeaders
    })
    return data
  }

  async existingRetailMember ({
    email = '',
    cardNumber = '',
    countryCallingCode = '',
    phoneNumber = '',
    recaptchaResponse = ''
  }) {
    try {
      const { data } = await this.client.post(`${this.accountUrl}/check_membership`, {
        membership_checker: {
          email,
          card_number: cardNumber,
          country_calling_code: countryCallingCode,
          mobile_phone: phoneNumber
        },
        'g-recaptcha-response': recaptchaResponse
      }, {
        headers: v2m5mHeaders
      })

      return { success: true, data: this.adapters.user.adaptData(data) }
    } catch (error) {
      return { success: false, errors: this.adapters.user.adaptError(error.response) }
    }
  }

  async createSession ({
    email = '',
    password = '',
    recaptchaResponse = '',
    merge = true,
    cartToken = get(vueSupport, 'cart.token', '')
  }) {
    const { data } = await this.client.post(`${this.authUrl}/login`, {
      user: {
        email,
        password
      },
      merge,
      'g-recaptcha-response': recaptchaResponse
    }, {
      headers: {
        'X-Cart-Token': cartToken,
        ...v2m5mHeaders
      }
    })
    return data
  }

  async marketplaceSignIn ({
    email = '',
    cardNumber = '',
    type = '',
    linkingToken = '',
    recaptchaResponse = ''
  }) {
    const { data } = await this.client.post(`${this.authUrl}/marketplaces/onboard`, {
      user: {
        email,
        card_number: cardNumber
      },
      'g-recaptcha-response': recaptchaResponse,
      linking_token: linkingToken,
      type: type
    }, {
      headers: {
        ...v2m5mHeaders
      }
    })
    return data
  }

  async digitalCatalogLogin ({
    email = '',
    dob = ''
  }) {
    const { data } = await this.client.post(`${this.authUrl}/digital_catalog/sessions`, {
      email,
      dob
    }, {
      headers: {
        ...v2m5mHeaders
      }
    })
    return data
  }

  async signIn ({
    email = '',
    password = '',
    recaptchaResponse = '',
    merge = true,
    cartToken = get(vueSupport, 'cart.token', ''),
    grantType = 'password',
    token = null,
    forterToken = '',
    code = '',
    state = '',
    codeVerifier = '',
    codeChallenge = '',
    otp = ''
  }) {
    const params = this.buildSignParams({
      email: email,
      password: password,
      recaptchaResponse: recaptchaResponse,
      merge: merge,
      grantType: grantType,
      token: token,
      forterToken: forterToken,
      code: code,
      state: state,
      codeVerifier: codeVerifier,
      codeChallenge: codeChallenge,
      otp: otp
    })
    const { data } = await this.client.post('auth/v1/token', params, {
      baseURL: '/api',
      skipAuthRefresh: true,
      headers: {
        'X-Cart-Token': cartToken,
        ...v2m5mHeaders
      }
    })
    return data
  }

  buildSignParams ({
    email = '',
    password = '',
    otp = '',
    recaptchaResponse = '',
    merge = true,
    grantType = 'password',
    token = null,
    forterToken = '',
    code = '',
    state = '',
    codeVerifier = '',
    codeChallenge = ''
  }) {
    switch (grantType) {
      case 'password':
        return {
          grant_type: grantType,
          login: email,
          password,
          flow: 'client',
          merge,
          'g-recaptcha-response': recaptchaResponse,
          forter_token: forterToken
        }
      case 'new_email_verification':
        return {
          grant_type: grantType,
          confirm_token: token,
          flow: 'client'
        }
      case 'social_email_verification':
        return {
          grant_type: grantType,
          email: email,
          password,
          flow: 'client',
          merge,
          'g-recaptcha-response': recaptchaResponse,
          forter_token: forterToken
        }
      case 'okta':
        return {
          grant_type: grantType,
          email: email,
          flow: 'client',
          code: code,
          state: state,
          codeVerifier: codeVerifier,
          codeChallenge: codeChallenge,
          merge,
          'g-recaptcha-response': recaptchaResponse,
          forter_token: forterToken
        }
      case 'otp':
        return {
          grant_type: grantType,
          flow: 'client',
          otp: otp,
          email: email,
          'g-recaptcha-response': recaptchaResponse,
          forter_token: forterToken
        }
    }
  }

  async destroySession () {
    const url = '/v2.5/sessions'
    const { data } = await this.client.delete(url, {
      headers: v2m5mHeaders
    })
    return data
  }

  async resetPassword ({
    email = ''
  }) {
    const url = '/v2.6/users/password'
    const { data } = await this.client.post(url, { user: { email } })
    return data
  }

  async verifyEmail ({
    email = ''
  }) {
    const url = '/v2.5/email_verification/send_verification_email'
    const { data } = await this.client.post(url, { user: { email } })
    return data
  }

  async resendOtp ({
    email = ''
  }) {
    const url = '/v2.6/otp_verifications'
    const { data } = await this.client.post(url, { user: { email } })
    return data
  }

  async createUser ({
    firstName = '',
    lastName = '',
    dob = '',
    mobile = {
      countryCallingCode: '',
      phoneNumber: ''
    },
    email = '',
    password = '',
    marketingCommunicationOptIns = false,
    marketingEmailCommunicationOptIns = false,
    recaptchaResponse = '',
    type = '',
    serviceAgreementsIds = [],
    forterToken = window.forterToken
  }) {
    const { data } = await this.client.post(`${this.authUrl}/register`, {
      user: {
        first_name: firstName,
        last_name: lastName,
        email,
        dob,
        country_calling_code: mobile.countryCallingCode,
        mobile_phone: mobile.phoneNumber,
        password,
        opt_ins: {
          mobile: marketingCommunicationOptIns,
          email: marketingEmailCommunicationOptIns
        },
        service_agreement_ids: serviceAgreementsIds
      },
      type: type,
      forter_token: forterToken,
      'g-recaptcha-response': recaptchaResponse
    }, {
      headers: {
        'X-Cart-Token': get(vueSupport, 'cart.token', ''),
        ...v2m5mHeaders
      }
    })

    return data
  }

  async createMarketplaceUser ({
    firstName = '',
    lastName = '',
    dob = '',
    mobile = {
      countryCallingCode: '',
      phoneNumber: ''
    },
    email = '',
    password = '',
    marketingCommunicationOptIns = false,
    marketingEmailCommunicationOptIns = false,
    recaptchaResponse = '',
    linkingToken = '',
    type = '',
    serviceAgreementsIds = []
  }) {
    const { data } = await this.client.post(`${this.authUrl}/marketplaces/register`, {
      user: {
        first_name: firstName,
        last_name: lastName,
        email,
        dob,
        country_calling_code: mobile.countryCallingCode,
        mobile_phone: mobile.phoneNumber,
        password,
        opt_ins: {
          mobile: marketingCommunicationOptIns,
          email: marketingEmailCommunicationOptIns
        },
        service_agreement_ids: serviceAgreementsIds
      },
      linking_token: linkingToken,
      type: type,
      'g-recaptcha-response': recaptchaResponse
    }, {
      headers: {
        'X-Cart-Token': get(vueSupport, 'cart.token', ''),
        ...v2m5mHeaders
      }
    })

    return data
  }

  async passwordlessSignup ({
    firstName = '',
    lastName = '',
    dob = '',
    mobile = {
      countryCallingCode: '',
      phoneNumber: ''
    },
    email = '',
    marketingCommunicationOptIns = false,
    marketingEmailCommunicationOptIns = false,
    recaptchaResponse = '',
    type = '',
    serviceAgreementsIds = [],
    forterToken = window.forterToken
  }) {
    const url = '/v2.6/account/sign_up'
    const { data } = await this.client.post(url, {
      user: {
        first_name: firstName,
        last_name: lastName,
        email,
        dob,
        country_calling_code: mobile.countryCallingCode,
        mobile_phone: mobile.phoneNumber,
        opt_ins: {
          mobile: marketingCommunicationOptIns,
          email: marketingEmailCommunicationOptIns
        },
        service_agreement_ids: serviceAgreementsIds
      },
      type: type,
      forter_token: forterToken,
      'g-recaptcha-response': recaptchaResponse
    }, {
      headers: {
        'X-Cart-Token': get(vueSupport, 'cart.token', ''),
        ...v2m5mHeaders
      }
    })

    return data
  }

  async createUnverifiedUser ({
    firstName = '',
    lastName = '',
    dob = '',
    mobile = {
      countryCallingCode: '',
      phoneNumber: ''
    },
    email = '',
    password = '',
    marketingCommunicationOptIns = false,
    marketingEmailCommunicationOptIns = false,
    recaptchaResponse = '',
    type = '',
    serviceAgreementsIds = [],
    forterToken = window.forterToken
  }) {
    const { data } = await this.client.post(`${this.authUrl}/register/create_unverified`, {
      user: {
        first_name: firstName,
        last_name: lastName,
        email,
        dob,
        country_calling_code: mobile.countryCallingCode,
        mobile_phone: mobile.phoneNumber,
        password,
        opt_ins: {
          mobile: marketingCommunicationOptIns,
          email: marketingEmailCommunicationOptIns
        },
        service_agreement_ids: serviceAgreementsIds
      },
      type: type,
      forter_token: forterToken,
      'g-recaptcha-response': recaptchaResponse
    }, {
      headers: {
        'X-Cart-Token': get(vueSupport, 'cart.token', ''),
        ...v2m5mHeaders
      }
    })

    return data
  }

  async createRetailAcquisition ({
    firstName = '',
    lastName = '',
    dob = '',
    mobile = {
      countryCallingCode: '',
      phoneNumber: ''
    },
    email = '',
    country = '',
    marketingCommunicationOptIns = false,
    recaptchaResponse = '',
    storeCode = '',
    channelCode = '',
    baId = '',
    serviceAgreementsIds = []
  }) {
    const { data } = await this.client.post(`${this.authUrl}/acquisitions`, {
      user: {
        first_name: firstName,
        last_name: lastName,
        email,
        dob,
        country_calling_code: mobile.countryCallingCode,
        mobile_phone: mobile.phoneNumber,
        country,
        marketing_communication_opt_ins: marketingCommunicationOptIns,
        store_code: storeCode,
        channel_code: channelCode,
        beauty_advisor_id: baId,
        service_agreement_ids: serviceAgreementsIds
      },
      'g-recaptcha-response': recaptchaResponse
    }, {
      headers: {
        ...v2m5mHeaders
      }
    })

    return data
  }

  exchangeToken (token, options = {}) {
    const url = '/sessions/cookies'
    return this.client.get(url, {
      params: options,
      baseURL: '/'
    })
  }

  async createSocialSession ({
    token = '',
    provider = '',
    cartToken = get(vueSupport, 'cart.token', ''),
    merge = true
  } = {}) {
    const url = '/v2.6/auth/social/login'
    const { data } = await this.client.post(url, {
      social: {
        provider,
        provider_token: token,
        merge
      },
      forter_token: window.forterToken
    }, {
      headers: {
        ...v2m5mHeaders,
        'X-Cart-Token': cartToken
      }
    })
    return data
  }

  async marketplaceSocialSignIn ({
    provider = '',
    token = '',
    linkingToken = '',
    type = ''
  }) {
    const { data } = await this.client.post(`${this.authUrl}/social/marketplace_onboard`, {
      social: {
        provider,
        provider_token: token
      },
      type,
      linkingToken
    }, {
      headers: {
        ...v2m5mHeaders
      }
    })
    return data
  }

  async registerSocialMarketplaceUser ({
    token = '',
    provider = '',
    mobile = {
      countryCallingCode: '',
      phoneNumber: ''
    },
    firstName = '',
    lastName = '',
    mobileOptIn = false,
    emailOptIn = false,
    type = '',
    linkingToken = '',
    serviceAgreementsIds = []
  }) {
    try {
      const { data } = await this.client.post(`${this.authUrl}/social/marketplace_register`, {
        social: {
          provider,
          token
        },
        user: {
          country_calling_code: mobile.countryCallingCode,
          mobile_phone: mobile.phoneNumber,
          opt_ins: {
            email: emailOptIn,
            mobile: mobileOptIn
          },
          service_agreement_ids: serviceAgreementsIds,
          ...(firstName ? { first_name: firstName } : {}),
          ...(lastName ? { last_name: lastName } : {})
        },
        linking_token: linkingToken,
        type: type
      })

      return { success: true, data: data.included[0] }
    } catch (error) {
      return { success: false, errors: this.adapters.user.adaptError(error.response) }
    }
  }

  async registerSocialUser ({
    token = '',
    provider = '',
    forterToken = '',
    mobile = {
      countryCallingCode: '',
      phoneNumber: ''
    },
    firstName = '',
    lastName = '',
    mobileOptIn = false,
    emailOptIn = false,
    serviceAgreementsIds = []
  }) {
    try {
      const { data } = await this.client.post('/v2.6/auth/social/register', {
        social: {
          provider,
          token
        },
        user: {
          country_calling_code: mobile.countryCallingCode,
          mobile_phone: mobile.phoneNumber,
          opt_ins: {
            email: emailOptIn,
            mobile: mobileOptIn
          },
          service_agreement_ids: serviceAgreementsIds,
          ...(firstName ? { first_name: firstName } : {}),
          ...(lastName ? { last_name: lastName } : {})
        },
        forter_token: forterToken
      }, {
        headers: {
          'X-Cart-Token': get(vueSupport, 'cart.token', '')
        }
      })

      return { success: true, data: data.included[0] }
    } catch (error) {
      return { success: false, errors: this.adapters.user.adaptError(error.response, { withMeta: true }) }
    }
  }

  async validatePasswordResetToken (token) {
    const endpoint = `/v2.6/passwords/reset_tokens/${token}`
    const { data } = await this.client.get(endpoint)

    return data
  }

  async validatePassword ({
    resetPasswordToken = '',
    type = '',
    password = '',
    confirmPassword = ''
  }) {
    const user = {
      type,
      password,
      confirm_password: confirmPassword
    }

    if (resetPasswordToken) user.reset_password_token = resetPasswordToken

    const url = '/v2.3/password/validators'
    const { data } = await this.client.post(url, {
      user: {
        type,
        password,
        confirm_password: confirmPassword,
        ...(resetPasswordToken ? { 'reset_password_token': resetPasswordToken } : {})
      }
    })

    return data
  }

  async updateUserPassword ({
    resetPasswordToken = '',
    password = '',
    confirmPassword = ''
  }) {
    try {
      const url = '/v2.6/users/password'
      const response = await this.client.patch(url, {
        user: {
          reset_password_token: resetPasswordToken,
          password,
          password_confirmation: confirmPassword
        },
        forter_token: window.forterToken
      })
      return response
    } catch (error) {
      // response format is slightly different for 401 errors
      return error.status === 401 ? error : error.response
    }
  }

  async updateLatestPrivacyConsent () {
    try {
      const url = '/api/v2.6/account/user'
      const response = await this.client.patch(url, {
        user: {
          accepted_latest_privacy_policy: true
        }
      })
      return response
    } catch (error) {
      notifyBugsnag(error)
    }
  }
}

export default Authentication
