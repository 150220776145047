function isSsrActivated () {
  return ['staging', 'production'].includes(vueSupport.env)
}

function isListingPages (to, from) {
  return (
    ['categories-show', 'brands-show'].includes(to.name) && from.path !== '/'
  ) // to prevent redirect loop when accessing categories and brands page directly
}

function isHomepage (to, from) {
  return to.path === '/' && from.path !== '/'
}

export { isSsrActivated, isListingPages, isHomepage }
