import { SplitFactory } from '@splitsoftware/splitio'
import secrets from '@/modules/config/secrets'
import { getCookie } from '@/modules/services/cookies'

const DEV_CONFIG = {
  features: {
    enable_auth_register_service_agreements: 'off',
    ssr_activated: 'off',
    ssr_listing_pages_activated: 'off',
    enable_video_gift_message_section: 'off',
    disable_user_registration: 'off',
    enable_product_summary_card: 'off',
    enable_vue_recaptcha: 'off',
    enable_account_verification: 'off',
    enable_crm_update_notification: 'off',
    enable_pdp_image_content: 'off',
    enable_product_image_content: 'off',
    enable_browse_products_btn_on_dfy: 'off',
    enable_url_sanitization: 'off',
    enable_marketplace_orders: 'off',
    enable_okta_login: 'off',
    enable_web_click_and_collect: 'off',
    enable_click_and_collect_pdp: 'off',
    enable_cash_rebate: 'off',
    xprovider_header: {
      treatment: 'staging-tf4rec-honeybee'
    }
  }
}

class SplitIO {
  // Reference https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK

  constructor () {
    this.clientKey = secrets.splitIOAuthorizationKey
    this.factory = null
    this.client = null
    this.isSDKReady = false

    this.initialize()
  }

  initialize () {
    this.factory = SplitFactory(this.getConfig())

    this.client = this.factory.client()
    this.client.on(this.client.Event.SDK_READY, () => {
      this.isSDKReady = true
    })
  }

  getConfig () {
    const devEnv = this.clientKey === 'localhost'

    const config = {
      core: {
        authorizationKey: this.clientKey,
        key: getCookie('user-bucket-id') || 'jssdk'
      },
      scheduler: {
        segmentsRefreshRate: 60 // prevent unnecessary polling from split servers too often
      },
      storage: {
        type: 'LOCALSTORAGE',
        prefix: 'MYPREFIX'
      },
      // this config is only available on development - loaded on when required
      // temporary solution to add your features here in when developing on local
      // example { features: { 'name_of_toggle': 'on' } }
      ...(devEnv ? DEV_CONFIG : {})
    }

    return config
  }

  getTreatment (splitName = '', params = {}) {
    // https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#basic-use

    const client = this.client

    if (this.isSDKReady) {
      return treatmentToBoolean(client.getTreatment(splitName, { ...params, ...DEFAULT_PARAMS }))
    }

    return new Promise(resolve => {
      client.on(client.Event.SDK_READY, () => {
        resolve(treatmentToBoolean(client.getTreatment(splitName, { ...params, ...DEFAULT_PARAMS })))
      })
    })
  }

  getTreatmentWithConfig (splitName = '', params = {}) {
    // https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#get-treatments-with-configurations

    const client = this.client

    if (this.isSDKReady) {
      return client.getTreatmentWithConfig(splitName, { ...params, ...DEFAULT_PARAMS })
    }

    return new Promise(resolve => {
      client.on(client.Event.SDK_READY, () => {
        resolve(client.getTreatmentWithConfig(splitName, { ...params, ...DEFAULT_PARAMS }))
      })
    })
  }

  getTreatments (splitNames = [], params = {}) {
    if (!this.isSDKReady) return false

    // https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#multiple-evaluations-at-once
    return treatmentToBoolean(this.client.getTreatments(splitNames, { ...params, ...DEFAULT_PARAMS }))
  }
}

// private functions/variables
const DEFAULT_PARAMS = {
  country: window.I18n.siteCountryLs.toUpperCase(),
  platform: window.vueSupport.isMobile ? 'mobile_web' : 'web'
}

function treatmentToBoolean (value) {
  return value === 'on'
}

export default new SplitIO()
