import ApiBase from './api-base'
import { v2m5mHeaders } from '@/modules/config/api'
import { notifyBugsnag } from '@/modules/services/bugsnag'
import { setCookie } from '@/modules/services/cookies'

class Account extends ApiBase {
  constructor () {
    super()
    this.url = '/v2.3/account'
  }

  async fetchPurchases ({
    brandId = '',
    reviewed = '',
    pageNumber = '',
    pageSize = 10
  }) {
    const url = `${this.url}/purchases`
    const { data } = await this.client.get(url, {
      params: {
        'page[number]': pageNumber,
        'page[size]': pageSize,
        ...(brandId ? { 'filter[brand_ids]': brandId } : {}),
        ...(reviewed ? { 'filter[reviewed]': reviewed } : {}),
        include: 'review,product.brand'
      }
    })
    const expiryTime = new Date(Date.now() + 24 * 60 * 60 * 1000)
    if (data.meta['uas-token']) {
      setCookie('uas-token', data.meta['uas-token'], expiryTime, '/')
      let bvTokenMetaTag = document.querySelector('meta[name="bv:userToken"]')

      if (!bvTokenMetaTag) {
        bvTokenMetaTag = document.createElement('meta')
        bvTokenMetaTag.setAttribute('name', 'bv:userToken')
        document.head.appendChild(bvTokenMetaTag)
      }

      bvTokenMetaTag.setAttribute('content', data.meta['uas-token'])
    }
    return data
  }

  async fetchPointLogs ({
    pageSize = 15,
    pageNumber = 1,
    filterPoints = ''
  } = {}) {
    const url = `${this.url}/point_logs`
    const { data } = await this.client.get(url, {
      params: {
        'page[number]': pageNumber,
        'page[size]': pageSize,
        ...(filterPoints.length > 0 ? { 'filter[points]': filterPoints } : {})
      }
    })
    return data
  }

  async fetchUser () {
    const url = '/v2.6/account/user'
    const { data } = await this.client.get(url)
    return data
  }

  async fetchPointExpiryForecasts () {
    const url = `/v2.5/account/point_expiry_forecasts`
    const { data } = await this.client.get(url, {
      headers: v2m5mHeaders
    })
    return data
  }

  async fetchPurchasedVariants ({
    q,
    pageSize = 8,
    pageNumber = 1
  } = {}) {
    const url = '/v2.5/account/purchases/variants'
    const params = {
      'page[size]': pageSize,
      'page[number]': pageNumber
    }

    if (q) {
      params['q'] = q
    }

    const { data } = await this.client.get(url, {
      headers: v2m5mHeaders,
      params: params
    })

    return data
  }

  async fetchPurchasedRewards ({
    q,
    pageSize = 8,
    pageNumber = 1
  } = {}) {
    const url = '/v2.5/account/purchases/rewards'
    const params = {
      'page[size]': pageSize,
      'page[number]': pageNumber
    }

    if (q) {
      params['q'] = q
    }

    const { data } = await this.client.get(url, {
      headers: v2m5mHeaders,
      params: params
    })

    return data
  }

  async fetchPurchasedSamples ({
    q,
    pageSize = 8,
    pageNumber = 1
  } = {}) {
    const url = '/v2.5/account/purchases/samples'
    const params = {
      'page[size]': pageSize,
      'page[number]': pageNumber
    }

    if (q) {
      params['q'] = q
    }

    const { data } = await this.client.get(url, {
      headers: v2m5mHeaders,
      params: params
    })

    return data
  }

  async triggerResetPassword (email) {
    var url = '/v2.5/auth/request_reset_password'
    const { data } = await this.client.post(url, email)
    return data
  }

  async updateUserAccount (params) {
    const url = '/v2.5/account/user'
    const response = await this.client.patch(url, params)
    return response
  }
}

export async function onboard (api = new Account()) {
  const url = '/api/v2.6/account/onboarding'
  return api.client.post(url)
}

export default Account

export async function updateDOB (dob, api = new Account()) {
  try {
    const url = '/api/v2.6/account/user'
    return api.client.patch(url, { user: { date_of_birth: dob } })
  } catch (error) {
    notifyBugsnag(error)
  }
}
